





































import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";

import IconClose from "@/app/components/Icons/IconClose.vue";

export default defineComponent({
  props: {
    range: {
      type: Boolean,
      default: () => false, 
    },
    value: {
      type: [String, Array, Number] as PropType<string | number | (number | null)[]>,
      required: true,
    },
  },
  name: "DateRangePicker",
  components: {
    IconClose,
    DatePicker, 
  },
  setup(props, { emit }) {
    const format = ref("DD.MM.YY");
    const datePickerData = ref(_.cloneDeep(props.value));
    const isOpened = ref(false);
    const maskForInput = computed(() => props.range? '##.##.## ~ ##.##.##' : '##.##.##');

    const selectLabelIsActive = computed(() => {
      if (isOpened.value) return true;
      return _.isArray(props.value)? !!_.compact(props.value).length : !!props.value;
    });

    function toggleIsOpened(ev: boolean) {
      isOpened.value = ev;
    }
    // Кастомная обработка инпута т.к. ввод даты происходит через кастомный инпут
    function changeInput(ev: string) {
      if (props.range) {
        const value = ev && ev.split('~');
        const start = ev && +dayjs(value[0], format.value) || null;
        const end = ev && +dayjs(value[1], format.value).endOf('d') || null;
        emit('input', (!end || !start)? [null, null] : [`${end < start? dayjs(end).startOf('d').valueOf() : start}`, `${end}`]);
        return;
      }
      emit('input', `${+dayjs(ev, format.value)}`);
    }

    function onSelectDate(date: number | number[] | null[] | null) {
      emit('input', _.isArray(date)? date.map((d, i) => _.isNull(d)? d : `${i && dayjs(d).endOf('d').valueOf() || d}`) : date &&`${date}` || null);
    }

    watch(
      () => props.value,
      (val) => {
        if (!val) return;
        if (props.range && _.isArray(props.value)) {
          datePickerData.value = _.cloneDeep(props.value).map(el => el && _.toNumber(el) || el);
        }
        else {
          datePickerData.value = _.cloneDeep(+val);
        }
      },
      {
        deep: true,
        immediate: true, 
      },
    );
    return {
      datePickerData,
      selectLabelIsActive,
      toggleIsOpened,
      format,
      changeInput,
      maskForInput,
      onSelectDate,
    };
  },
});
